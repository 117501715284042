import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../styles/PropertySitePage.css'; // Import your CSS file
import familyAtPool from '../images/family-at-pool.png';
import lakeCabin from '../images/lake-cabin.png';
import homePhoto from '../images/home_photo.jpg';
import {
    MDBCarousel,
    MDBCarouselItem,
    MDBCarouselCaption, MDBBtn,
    MDBNavbar,
    MDBNavbarToggler, MDBIcon, MDBInput,
    MDBTextArea,
    MDBNavbarBrand
} from 'mdb-react-ui-kit';
import realtorWorking from "../images/RealtorWorking.png";
import soldProperty from "../images/SoldProperty.png";
import virtualAssistant from "../images/VirtualRealtyAssistant.png";
import davenportHouse from "../images/davenport.png";
import northLoganHouse from "../images/north-logan.png";
import gardenCityHouse from "../images/garden-city-3.png";
import gardenCity2House from "../images/garden-city-2.png";
import redBeardLogo from "../images/red-beard-logo-sm-3.png";
import fifthHouse from "../images/logan.png";
const PropertySitePage = () => {
    const { userId } = useParams();
    const [openBasic, setOpenBasic] = useState(false);
    const [name, setName] = useState(false);
    const [email, setEmail] = useState(false);
    const [subject, setSubject] = useState(false);
    const [message, setMessage] = useState(false);

    const sendContactForm = async () => {
        console.log('inside sendContactForm');
            try {
                await axios.post('http://localhost:3000/api/email/redbeard', {
                    name,
                    email,
                    subject,
                    message
                }).then((response) => {
                    console.dir(response.data.data);
                }).catch((e) => console.log("Error : ", e));

            } catch (error) {
                console.log("Error sending contact form: ", error);
            }
    }
    // Fetch all the information for the website using the ID and render the site accordingly

    // Nav Bar
    const navbar = (
        <MDBNavbar expand='lg' className="navbar navbar-expand-lg fixed-top navbar-scroll" style={{backgroundColor:"#00000087"}}>
            <div className="container">
                <MDBNavbarBrand href='#'>
                    <img
                        src={redBeardLogo}
                        height='30'
                        alt=''
                        loading='lazy'
                    />&nbsp;
                    Red Beard PM
                </MDBNavbarBrand>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="#home">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#rental-homes">Rental Homes</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#management-services">Management Services</a>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" href="#tenants">Tenants</a>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" href="#owners">Owners</a>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <a className="nav-link" href="#about-us">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#contact-us">Contact Us</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav d-flex flex-row">
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="tel:435-554-8196">
                                <i className="fas fa-phone"></i>&nbsp;
                                435-554-8196
                            </a>
                        </li>
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="mailto:info@redbeardpropertymanagement.com">
                                <i className="fas fa-envelope"></i>&nbsp;
                                info@redbeardpropertymanagement.com
                            </a>
                        </li>
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="https://www.facebook.com/profile.php?id=100091442632867" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook"></i>
                            </a>
                        </li>
                        <li className="nav-item me-3 me-lg-0">
                            <a className="nav-link" href="https://www.instagram.com/redbeard.rentals/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </MDBNavbar>
    );

    // Home - Top section with photo slideshow and menu
    const home = (
        <MDBCarousel showIndicators showControls fade>
            <MDBCarouselItem itemId={1}>
                <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(15).webp' className='d-block w-100' alt='...' />
                <MDBCarouselCaption>
                    <h5>First slide label</h5>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </MDBCarouselCaption>
            </MDBCarouselItem>

            <MDBCarouselItem itemId={2}>
                <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(22).webp' className='d-block w-100' alt='...' />
                <MDBCarouselCaption>
                    <h5>Second slide label</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </MDBCarouselCaption>
            </MDBCarouselItem>
            <MDBCarouselItem itemId={3}>
                <img src='https://mdbootstrap.com/img/Photos/Slides/img%20(23).webp' className='d-block w-100' alt='...' />
                <MDBCarouselCaption>
                    <h5>Third slide label</h5>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                </MDBCarouselCaption>
            </MDBCarouselItem>
        </MDBCarousel>
    );

    // Video
    const video = (
        <section id="home">
            <div id="intro" className="bg-image">
                <div className="ratio ratio-16x9">
                    <img
                        src={homePhoto}
                        className="img-fluid"
                        alt="Phone image"
                    />
                </div>
            </div>
        </section>
    );

    // About Us
    const aboutUs = (
        <div className="container my-5" id="about-us">
            <section>
                <h3 className="text-center mb-5">About us</h3>

                <div className="row">
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <h5 className="text-dark text-center">01 | We are creative <i className="fas fa-brain"></i></h5>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, aliquam dolor
                            soluta praesentium natus alias eligendi.
                        </p>
                    </div>

                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <h5 className="text-dark text-center">02 | We are competent <i className="fas fa-puzzle-piece"></i></h5>
                        <p>
                            A expedita facilis enim et sequi sit similique fuga deleniti assumenda. Quisquam ea
                            corrupti laudantium assumenda.
                        </p>
                    </div>

                    <div className="col-lg-4 mb-0">
                        <h5 className="text-dark text-center">03 | We are experienced <i className="fas fa-lightbulb"></i></h5>
                        <p>
                            Oluptatum autem veniam quaerat sequi odit sit aliquam expedita, ab, excepturi
                            pariatur natus magnam possimus.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
    // Price & Features
    const features = (
        <section id="features">
            <div className="bg-image bg-fixed text-center" style={{
                backgroundImage: `url(${familyAtPool})`,
                height: "460px" }}>
                <div className="d-flex align-items-center h-100 mask" style={{
                    background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}}>
                    <div className="container text-white">
                        <p className="h1 mb-5">What you can expect from Red Beard</p>
                        <div className="row">
                            <div className="col-md-3">
                                <h2 className="h3">Expertise & Experience</h2>
                                <p className="mb-md-0">Handling tenant relations and maintenance.</p>
                            </div>
                            <div className="col-md-3">
                                <h2 className="h3">Freedom</h2>
                                <p className="mb-md-0">We will put in the time and energy so that you have less to worry about.</p>
                            </div>
                            <div className="col-md-3">
                                <h2 className="h3">Financial Management</h2>
                                <p className="mb-md-0">Monthly reports showing earnings and expenditures.</p>
                            </div>
                            <div className="col-md-3">
                                <h2 className="h3">Maximizing Results</h2>
                                <p className="mb-0">Finding the best tenants and keeping occupancy consistent.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

    // Amenities
    const amenities = (
        <div id="amenities"></div>
    );

    // Description
    const description = (
        <section id="description">
            <div className="bg-light" style={{height: "400px"}}>
                <div className="d-flex align-items-center justify-content-center text-center h-100">
                    <div className="container">
                        <h3 className="text-uppercase font-weight-bold my-4 py-2">
                            Your Property, Our Priority
                        </h3>
                        <p className="mb-0">
                            At Red Beard, we understand that managing property can be complex and time-consuming. That's why we're dedicated to making it easy for you. Our cutting-edge technology and expert team ensure that every aspect of property management is handled with precision and care. From tenant screening and lease management to maintenance requests and financial reporting, we handle it all so you can enjoy peace of mind. With us, your property isn’t just another listing—it’s a top priority.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );

    const videos = (
        <div id="videos"></div>
    );

    // Rental Homes
    const photos = (
        <div className="container-fluid my-5" id="rental-homes">
            <section className="text-center">
                <div className="container">
                    <h3 className="mb-5">Rent a home</h3>
                    <p className="mb-5 mx-auto w-responsive">
                        We manage short-term, mid-term, and long-term rentals.
                    </p>
                </div>

                <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="col-lg col-md-6 mb-4">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <a 
                                href="https://redbeardvacationhomes.hospitable.rentals/property/family-play-time-sleeps-26-w-hot-tub-fun-shop" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <img src={northLoganHouse} className="w-100" alt="Sample image" />
                                <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                    <MDBBtn outline className='mx-2' color='info'>
                                        SEE MORE
                                    </MDBBtn>
                                </div>
                            </a>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">North Logan, UT</p>
                    </div>
                    <div className="col-lg col-md-6 mb-4">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <a 
                                href="https://redbeardvacationhomes.hospitable.rentals/property/super-clean-new-3br3ba-one-block-to-marina" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <img src={gardenCityHouse} className="w-100" alt="Sample image" />
                                <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                    <MDBBtn outline className='mx-2' color='info'>
                                        SEE MORE
                                    </MDBBtn>
                                </div>
                            </a>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Garden City, UT</p>
                    </div>

                    <div className="col-lg col-md-6 mb-4">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <a 
                                href="https://redbeardvacationhomes.hospitable.rentals/property/huge-fun-home-sleeps-32-11-br-pool-arcade" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <img src={davenportHouse} className="w-100" alt="Sample image" />
                                <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                    <MDBBtn outline className='mx-2' color='info'>
                                        SEE MORE
                                    </MDBBtn>
                                </div>
                            </a>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Davenport, FL</p>
                    </div>
                    <div className="col-lg col-md-6 mb-4">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <a 
                                href="https://redbeardvacationhomes.hospitable.rentals/property/center-of-bear-lake-steps-from-beach-sleeps-26" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <img src={gardenCity2House} className="w-100" alt="Sample image" />
                                <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                    <MDBBtn outline className='mx-2' color='info'>
                                        SEE MORE
                                    </MDBBtn>
                                </div>
                            </a>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Garden City, UT</p>
                    </div>
                    <div className="col-lg col-md-6 mb-4">
                        <div className="bg-image hover-overlay shadow-1-strong rounded">
                            <a 
                                href="https://redbeardvacationhomes.hospitable.rentals/property/updated-clean-historic-home-by-usu-3br-1ba" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <img src={fifthHouse} className="w-100" alt="Sample image" />
                                <div className="mask d-flex align-items-center justify-content-center" style={{background: "linear-gradient(45deg, rgba(29, 236, 197, 0.7), rgba(91, 14, 214, 0.7) 100%)"}} data-mdb-theme="dark">
                                    <MDBBtn outline className='mx-2' color='info'>
                                        SEE MORE
                                    </MDBBtn>
                                </div>
                            </a>
                        </div>
                        <p className="text-uppercase mt-4 mb-0">Logan, UT</p>
                    </div>
                </div>


            </section>
        </div>
    );

    const floorPlans = (
        <div id="floor-plans"></div>
    );

    // Map
    const map = (
        <div id="map"></div>
    );
    // Documents
    const documents = (
        <div className="container my-5" id="management-services">
            <section className="text-center">


                <h3 className="text-center mb-5">What we do</h3>

                <div className="row">
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <div className="card hover-shadow-light">
                            <div className="card-body">
                                <i className="fas fa-clipboard-list fa-3x mt-3"></i>
                                <h5 className="card-title my-4">Tenant Management</h5>
                                <p className="card-text mb-3">
                                    Screening, agreements & contracts, rent collection, resolving issues.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <div className="card hover-shadow-light">
                            <div className="card-body">
                                <i className="fas fa-toolbox fa-3x mt-3"></i>
                                <h5 className="card-title my-4">Property Maintenance</h5>
                                <p className="card-text mb-3">
                                    Routine maintenance, emergency repairs, inspections.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                        <div className="card hover-shadow-light">
                            <div className="card-body">
                                <i className="fas fa-dollar-sign fa-3x mt-3"></i>
                                <h5 className="card-title my-4">Financial Management</h5>
                                <p className="card-text mb-3">
                                    Budgeting for repairs, book keeping, reporting.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-0">
                        <div className="card hover-shadow-light">
                            <div className="card-body">
                                <i className="fas fa-newspaper fa-3x mt-3"></i>
                                <h5 className="card-title my-4">Marketing and Leasing</h5>
                                <p className="card-text mb-3">
                                    Property advertising, showings, lease renewals.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

    const flyers = (
        <div id="flyers"></div>
    );

    // Agent
    const agent = (
        <div id="agent"></div>
    );
    // Share

    // Social icons
    const social = (
        <div className="container d-flex justify-content-center text-center py-5" id="share">
            <div className="row">
                <div className="col-12">
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='facebook-f' />
                    </MDBBtn>
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='twitter' />
                    </MDBBtn>
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='google-plus-g' />
                    </MDBBtn>
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='youtube' />
                    </MDBBtn>
                    <MDBBtn floating size='lg' tag='a'>
                        <MDBIcon fab icon='instagram' />
                    </MDBBtn>
                </div>
            </div>
        </div>
    );

    // Request Info / Book Appointment
    const contactUs = (
        <div className="container my-5" id="contact-us">
            <section className="mb-4">
                <h2 className="h1-responsive font-weight-bold text-center my-4">Contact us</h2>
                <p className="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
                    a matter of hours to help you.</p>
                <div className="row">
                    <div className="col-md-9 mb-md-0 mb-5">
                        <form id="contact-form" name="contact-form" action="mail.php" method="POST">
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <div className="md-form mb-0">
                                        <MDBInput label="Your name" id="name" type="text" onChange={(e) => setName((e.target).value)} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <div className="md-form mb-0">
                                        <MDBInput label="Your email" id="email" type="text" onChange={(e) => setEmail((e.target).value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="md-form mb-0">
                                        <MDBInput label="Subject" id="subject" type="text" onChange={(e) => setSubject((e.target).value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="md-form">
                                        <MDBTextArea label="Message" id="message" rows="{4}" onChange={(e) => setMessage((e.target).value)} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="text-center text-md-left">
                            <a data-mdb-ripple-init className="btn btn-primary" onClick={() => sendContactForm()}>Send</a>
                        </div>
                        <div className="status"></div>
                    </div>
                    <div className="col-md-3 text-center">
                        <ul className="list-unstyled mb-0">
                            <li>
                                <i className="fas fa-map-marker-alt fa-2x"></i>
                                <p>
                                    <a 
                                        href="https://www.google.com/maps/search/?api=1&query=Logan,+UT+84341,+USA" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none', color: 'var(--bs-body-color)' }}
                                    >
                                        Logan, UT 84341, USA
                                    </a>
                                </p>
                            </li>
                            <li>
                                <i className="fas fa-phone mt-4 fa-2x"></i>
                                <p>
                                    <a 
                                        href="tel:435-554-8196"
                                        style={{ textDecoration: 'none', color: 'var(--bs-body-color)' }}
                                    >
                                        435-554-8196
                                    </a>
                                </p>
                            </li>
                            <li>
                                <i className="fas fa-envelope mt-4 fa-2x"></i>
                                <p>
                                    <a 
                                        href="mailto:info@redbeardpropertymanagement.com"
                                        style={{ textDecoration: 'none', color: 'var(--bs-body-color)' }}
                                    >
                                        info@redbeardpropertymanagement.com
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );

    const footer = (
        <footer className="bg-light">
            <div className="text-center p-3" style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
                Red Beard Property Management © 2024
            </div>
        </footer>
    );

    return (
        <div>
            <header>
                { navbar }

                { video }
            </header>

            <main>
                { photos }

                { features }

                { description }

                { amenities }

                { videos }

                { documents }

                { aboutUs }

                { contactUs }

                { footer }
            </main>

        </div>);
};

export default PropertySitePage;
